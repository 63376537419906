<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid justify-content-center py-3 mt-10 mb-8 rounded bg-white"
    >
      <h3
        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg px-4 text-center"
      >
        Validez votre inscription {{ playzoneInfo.preposition }}
        {{ playzoneInfo.playzone_name }}
      </h3>
    </div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p v-if="playerInfo">
        {{ playerInfo.firstname }} {{ playerInfo.lastname }} vos informations
        sont bien enregistrées ! Veuillez confrimer votre email
        <span v-show="hasSMS">et numéro de téléphone</span> pour valider votre
        inscription
      </p>
      <p>
        Ce message sera fermé dans {{ dismissCountDown }} seconde{{
          dismissCountDown > 1 ? "s" : ""
        }}...
      </p>
    </b-alert>
    <div class="row">
      <div class="col col-xs-12" v-show="hasSMS">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <h2 class="card-title">Validation Numéro de téléphone</h2>
          </div>
          <div class="card h-100 card-body">
            <div v-if="validNumber">
              <sweetalert-icon icon="success"></sweetalert-icon>
              <p class="text--darken-2 text-center">
                Votre numéro +{{ playerInfo.phone }} a été bien vérifié !
              </p>
            </div>
            <div v-if="!validNumber">
              <p class="font-size-lg">
                Nous avons envoyé un SMS de validation à chiffres sur votre
                numéro <span v-if="playerInfo">+{{ playerInfo.phone }}</span
                >.
              </p>
              <p class="font-size-lg">
                Merci d'entrer le code reçu dans le champs ci-dessous et valider
              </p>
              <b-row class="my-1">
                <b-col sm="8">
                  <b-input-group class="my-2">
                    <b-form-input
                      :state="noCodeError"
                      v-model="codeOtp"
                      placeholder="Code à 4 chiffres"
                      @focus="removeError"
                    ></b-form-input>
                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-input-group-append>
                      <b-button variant="primary" @click="verifyOtp"
                        >Valider le code</b-button
                      >
                    </b-input-group-append>
                    <b-form-invalid-feedback
                      id="input-live-feedback"
                      v-if="!noCodeError"
                    >
                      Code non valide, veuillez réessayer
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-xs-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <h2 class="card-title">Validation Email</h2>
          </div>
          <div class="card-body">
            <div v-if="validEmail">
              <sweetalert-icon icon="success"></sweetalert-icon>
              <p class="mt-10 text-center">
                Votre Email ci-dessous a été bien vérifié !
              </p>
              <p>
                <i v-if="playerInfo"
                  >{{ playerInfo.email }}
                  <span v-if="!noEmailError"
                    ><a class="email-update" @click="toggleForm">{{
                      showForm === false ? "Modifier" : "Annuler"
                    }}</a></span
                  ></i
                >
              </p>
              <b-row class="my-1" v-if="showForm">
                <b-col sm="8" v-if="!noEmailError">
                  <b-input-group class="my-2">
                    <b-form-input
                      :state="!emailHasError"
                      type="email"
                      v-model="newEmail"
                      placeholder="Nouvelle adresse email"
                      @focus="removeEmailError"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="changeEmail"
                        >Valider</b-button
                      >
                    </b-input-group-append>
                    <b-form-invalid-feedback
                      id="input-email-feedback"
                      v-if="emailHasError"
                    >
                      Email non valide ! Veuillez vérifier l'adresse saisie.
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>

            <div v-if="!validEmail">
              <p class="font-size-lg">
                Nous avons envoyé un email de confirmation vers votre adresse
                email
              </p>
              <p>
                <i v-if="playerInfo"
                  >{{ playerInfo.email }}
                  <span v-if="!noEmailError"
                    ><a class="email-update" @click="toggleForm">{{
                      showForm === false ? "Modifier" : "Annuler"
                    }}</a></span
                  ></i
                >
              </p>
              <b-row class="my-1" v-if="showForm">
                <b-col sm="8" v-if="!noEmailError">
                  <b-input-group class="my-2">
                    <b-form-input
                      :state="!emailHasError"
                      type="email"
                      v-model="newEmail"
                      placeholder="Nouvelle adresse email"
                      @focus="removeEmailError"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="changeEmail"
                        >Valider</b-button
                      >
                    </b-input-group-append>
                    <b-form-invalid-feedback
                      id="input-email-feedback"
                      v-if="emailHasError"
                    >
                      Email non valide ! Veuillez vérifier l'adresse saisie.
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
              </b-row>

              <p class="font-size-lg">
                Ouvrez ce mail et confirmer votre adresse email pour valider
                votre inscription
              </p>
            </div>
            <b-input-group-append>
              <b-button variant="primary" @click="backToGame"
              >Retourner au jeu</b-button
              >
            </b-input-group-append>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SweetalertIcon from "vue-sweetalert-icons";

export default {
  name: "Validate",
  components: { SweetalertIcon },
  data() {
    return {
      validNumber: false,
      validEmail: false,
      playerInfo: {},
      playzoneInfo: {},
      redirectUri: null,
      showForm: false,
      newEmail: "",
      codeOtp: "",
      dismissSecs: 10,
      dismissCountDown: 10,
      showDismissibleAlert: false,
      noCodeError: true,
      noEmailError: false,
      emailUpdated: false,
      emailHasError: false,
      hasSMS: false,
      subscription: {
        isSubscribed: 0,
        phoneVerified: false,
        emailVerified: false
      }
    };
  },

  created() {
    this.playerInfo = JSON.parse(sessionStorage.getItem("memberInfo"));
    this.playzoneInfo = JSON.parse(sessionStorage.getItem("playzoneInfo"));
    this.subscription = JSON.parse(
      sessionStorage.getItem("memberInfo")
    ).subscription;
    this.redirectUri = sessionStorage.getItem("redirectUri");
    this.hasSMS = JSON.parse(sessionStorage.getItem("memberInfo")).hasSMS;
    if (this.subscription.emailVerified === true) {
      this.validEmail = true;
    }
    if (this.subscription.phoneVerified === true) {
      this.validNumber = true;
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    changeEmail() {
      console.log(this.validateEmail(this.newEmail));
      if (this.validateEmail(this.newEmail) !== true) {
        this.emailHasError = true;
        return false;
      }
      const payload = {
        firstname: this.playerInfo.firstname,
        lastname: this.playerInfo.lastname,
        email: this.newEmail,
        playerId: this.playerInfo.userId
      };
      ApiService.post("email-update", payload)
        .then(({ data }) => {
          if (data === true) {
            this.emailUpdated = true;
            this.noEmailError = true;
            this.playerInfo.email = this.newEmail;
            this.validEmail = false;
          } else {
            this.noEmailError = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    removeError() {
      this.noCodeError = true;
    },
    verifyOtp() {
      console.log(this.codeOtp);
      const payload = {
        playerId: this.playerInfo.userId,
        otpCode: this.codeOtp
      };
      console.log(payload);
      ApiService.post("number-validate", payload)
        .then(({ data }) => {
          console.log(data);
          if (data === true) {
            this.validNumber = true;
          } else {
            this.noCodeError = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    backToGame() {
      if (this.redirectUri != null) {
        window.location = `${this.redirectUri}?platform=messenger&platformId=${this.playerInfo.userId}`;
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    removeEmailError() {
      this.emailHasError = false;
    }
  }
};
</script>

<style lang="css" scoped>
.email-update {
  color: dodgerblue;
  cursor: pointer;
  text-decoration: underline;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #d0d0d0;
  background-image: none;
}
</style>
